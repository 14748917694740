.ui-gallery {
  padding: 1rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin-left: 1rem;
  margin-right: 1rem;
}

.ui-component-headings {
  background-color: var(--color-primary-dark) !important;
  color: var(--font-color-secondary) !important;
  border: 1px dashed !important;
  border-color: var(--font-color-primary) !important;
  padding: 1rem;
}

.ui-components {
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
}

.ui-component-block {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.width-200px {
  width: 200px;
}