.app-container {
  /* background: var(--color-primary); */
  background: #eeeeee
}

.safety-checkboxes {
  justify-content: center;
  display: flex;
  text-align: center;
  padding: 1rem !important;
}

.safety-checkboxes .MuiCheckbox-root {
  display: none;
}

.safety-checkboxes .safety-checkboxes-label {
  width: 12rem;
  height: 12rem;
  padding: 2.4rem 0;

}

.safety-checkboxes .safety-checkboxes-label .checkbox-img {
  width: 70px;
  height: 70px;
}

.safety-checkboxes .safety-checkboxes-active {
  background: #ccc;
}


.safety-checkboxes .safety-checkboxes-active .checkbox-img {
  opacity: 0.7 !important;
  width: 70px;
  height: 70px;
}

.safety-checkboxes.add-plan .safety-checkboxes-label .checkbox-img {
  width: 30px;
  height: 30px;
}

.safety-checkboxes.add-plan .safety-checkboxes-active .checkbox-img {
  opacity: 0.7;
  width: 30px;
  height: 30px;
}

.safety-checkboxes.add-plan .safety-checkboxes-active {
  background: initial;
}

.safety-checkboxes.add-plan p {
  font-size: 7px;
  display: block;
}

.safety-checkboxes.add-plan .safety-checkboxes-label {
  width: 4rem;
  height: 4rem;
  padding: 0;
  position: relative;
}

.safety-checkboxes.add-plan .safety-checkboxes-label svg {
  display: none;
}

.safety-checkboxes.add-plan .safety-checkboxes-active img {
  opacity: 0.9 !important;
}

.safety-checkboxes.add-plan .safety-checkboxes-active svg {
  position: absolute;
  font-size: 35px;
  color: green;
  margin: 0 auto;
  display: block;
  top: 38px;
  left: 17px;
}

.safety-checkboxes.review .safety-checkboxes-label {
  position: relative;
}

.safety-checkboxes.review .safety-checkboxes-label svg {
  display: none;
}

.safety-checkboxes.review .safety-checkboxes-active img {
  opacity: 0.9 !important;
}

.safety-checkboxes.review .safety-checkboxes-active svg {
  position: absolute;
  font-size: 57px;
  color: green;
  margin: 0 auto;
  display: block;
  top: 125px;
  left: 40%;
}

.plan-esign {}

#globle-loader {
  display: none;
}

#globle-loader.show-loader {
  display: flex;
}

.agreement-pdf {
  width: 62vw;
  height: 70vh;
}

.selected-chb {
  padding-left: 3rem !important;
}

.red-border {
  border: 5px solid red;
}

.green-border {
  border: 5px solid green;
}

.illustration-inspector .MuiCheckbox-colorSecondary.Mui-disabled {
  color: red;
}

.illustration-inspector .MuiCheckbox-colorSecondary.Mui-checked {
  color: green;
}

/* tickets */
.status {
  background-color: #333;
  color: #fff;
  width: 100px;
  padding: 0 20px;
  justify-self: center;
  border-radius: 10px;
  font-size: 16px;
  text-align: center;
  float: right;
  text-transform: capitalize;
}

.status-new {
  background-color: green;
  color: #fff;
  border-radius: 10px;
}

.status-open {
  background-color: steelblue;
  color: #fff;
  border-radius: 10px;
}

.status-close {
  background-color: darkred;
  color: #fff;
  border-radius: 10px;
}

.ticket-page {
  position: relative;
  text-align: left;
}

.ticket-desc {
  margin: 20px 10px !important;
  font-size: 17px;
  background-color: #f4f4f4;
  border: 1px #ccc solid;
  padding: 10px 15px !important;
  border-radius: 5px;
}

.note {
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  text-align: left;
  padding: 20px;
  margin-bottom: 20px !important;
  position: relative;
}

.note-head {
  background: #f4f4f4;
  padding: 5px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.note-date {
  position: absolute;
  top: 15px;
  right: 10px;
  font-size: 14px;
}

.delete-note {
  color: red;
  cursor: pointer;
  position: absolute;
  bottom: 10px;
  right: 20px;
}

.ticket-page .btn-block {
  width: 100%;
  margin-top: 30px;
}

.MuiFormControl-fullWidth {
  text-align: left;
}

.revProject {
  background: #ccc;
}

.float-right {
  float: right;
}

.green-button {
  background-color: #4caf50 !important;
  color: #FFFFFF !important
}

.green-button:hover {
  background-color: #388e3c !important;
}

@media screen and (max-width: 959px) {
  .what-how-why svg {
    transform: rotate(90deg);
  }
}

@media screen and (max-width: 821px) {
  .sm-none {
    display: none !important;
  }
}

@media screen and (max-width: 599px) {
  .xs-none {
    display: none !important;
  }
}

@media screen and (max-width: 427px) {
  .technician-action .main-image svg {
    display: none;
  }

  .technician-action .main-image span {
    display: block;
    position: initial !important;
    margin-left: 0 !important;
  }
}

@media screen and (max-width: 900px) {
  .technician-action .main-image svg {
    display: none;
  }

  .technician-action .main-image span {
    display: block;
    position: initial !important;
    margin-left: 0 !important;
  }
}