/* .custom-tf {
  color: var(--font-color-primary) !important;
} */

/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~CHECK IF THIS IS REQUIRED~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
.custom-tf>label {
  color: var(--font-color-primary) !important;
}

textarea {
  color: var(--font-color-accent);
  background: #e0e0e0
  /* var(--color-primary-dark); */
}

.custom-tf > .MuiFilledInput-underline:after {
  border-bottom-color: var(--color-accent);
}
/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

.lgs-text-field {
  margin-left: 1rem;
  margin-right: 1rem;
}