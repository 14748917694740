.form-control:focus {
  border-color: var(--color-accent);
  box-shadow: 0 0 0 0.2rem var(--color-accent-light);
}

.rdd-custom .Dropdown-control {
  background-color: var(--color-primary-dark);
  color: var(--font-color-accent);
  border: 0px;
  border-radius: 2px;
  padding: 5px 10px;
}

.rdd-custom .Dropdown-option{
  padding: 5px 10px;
}

.rdd-custom .Dropdown-menu {
  background: var(--color-primary-light);
  border: 0px;
  color: #fff;
  width: 90%;
  margin-left: 5%;
}

.rdd-custom .Dropdown-root {
  width: 16%;
  text-align: left;
  font-size: 14px;
}

.rdd-custom .Dropdown-control {
  padding-top: 10px;
  padding-bottom: 10px;
  border: 1px solid var(--color-accent);
}

.rdd-custom .Dropdown-option {
  padding: 8px 10px;
}

.rdd-custom .Dropdown-menu {
  width: 100%;
  margin-left: 0px;
  border: 1px solid var(--color-accent);
}

.rdd-custom .Dropdown-option.is-selected {
  background: var(--color-primary-dark);
  color: #fff;
}