html{
  zoom: 0.80;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.react-datepicker-wrapper{width: 100%}
.react-datepicker-popper{z-index: 9;}
.MuiTableCell-body{color: inherit !important}
.MuiButton-contained.Mui-disabled{background-color:#e0e0e0 !important}
/* .MuiAutocomplete-popper{z-index: 99999;} */
/* @import '../node_modules/bootstrap/dist/css/bootstrap.css'; */
@import '../node_modules/font-awesome/css/font-awesome.min.css'; 