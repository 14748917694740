.theme-default {
  --color-primary: #FFFFFF;
  --color-primary-dark: #7C746D;
  --color-primary-light: #C0C0C4;
  --color-accent: #000000;
  --color-accent-light: #1E2D7F;
  --color-accent-hover: #919EE9;
  --font-color-primary: #000000;
  --font-color-secondary: #FFFFFF;
  --font-color-accent: #FFFFFF;
  --font-color-primary-hover: #000000;
  --font-color-secondary-hover: #FFFFFF;
  --theme-red: #F62E00;
  --theme-amber: #F7931E;
  --theme-green: #00AC69;
}

.theme-dark {
  --color-primary: #252559;
  --color-primary-dark: #1A0A38;
  --color-primary-light: #3a3a88;
  --color-accent: #00AC69;
  --color-accent-light: #03b87263;
  --color-accent-hover: #008d57;
  --font-color-primary: #FFFFFF;
  --font-color-secondary: #FFFFFF;
  --font-color-accent: #FFFFFF;
  --font-color-primary-hover: #000000;
  --font-color-secondary-hover: #FFFFFF;
  --theme-red: #F62E00;
  --theme-amber: #F7931E;
  --theme-green: #00AC69;
}