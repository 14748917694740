.btn-base-class {
  background-color: var(--color-accent);
  color: var(--font-color-secondary);
}

.btn-base-class:hover {
  background-color: var(--color-accent-hover);
  color: var(--font-color-secondary-hover);
}

.btn-base-class:focus {
  border-color: var(--color-accent);
  box-shadow: 0 0 0 0.2rem var(--color-accent-light);
}